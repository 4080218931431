import React from 'react';
import { graphql } from 'gatsby';
import { PageCGF } from '@src/ui';
import { usePageList } from '@src/hooks/page-list';

const Page: React.FC = () => {
  const pages = usePageList();
  const currentPageData = pages.find(p => p.id === 'contact');
  return <PageCGF image={currentPageData?.image} />;
};

export const query = graphql`
  query CGF {
    image: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 240, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Page;
